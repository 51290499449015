/* Full-screen loading container */
.loading-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent background */
    z-index: 9999;
}

/* Spinner animation */
.spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #d1d1d1;
    border-top: 5px solid #000; /* Accent color for the spinner */
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
}

/* Keyframes for spinner */
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Loading message */
.loading-message {
    font-family: Arial, sans-serif;
    font-size: 16px;
    color: #555;
}