.video-list {
    display: flex;
    gap: 16px;
    justify-content: flex-start;
    overflow: scroll;
    margin-left: calc(var(--margin-from-sides-of-page) * -1);
    padding-left: var(--margin-from-sides-of-page);
    width: calc(100% + var(--margin-from-sides-of-page));
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding-bottom: 5px;
}

.product-video:last-child {
    margin-right: var(--margin-from-sides-of-page);
}