.rating-stars {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #a1a1a1; /* Text color */
    gap: 6px;
}

.rating-value {
    font-weight: bold;
    margin-right: 4px;
    color: #a1a1a1;
}

.stars {
    display: flex;
    align-items: center;
    gap: 2px;
    margin-right: 4px;
}

.star {
    font-size: 16px;
    color: #ffcc00; /* Star color */
}

.star.empty {
    color: #ffcc00; /* Lighter color for empty stars */
}

.num-reviews {
    font-size: 14px;
    color: #a1a1a1;
}
