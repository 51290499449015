.carousel-container {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
    background-color: #f8f8f8;
}

.carousel-image-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.carousel-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.carousel-image.active {
    opacity: 1;
}

.gradient-overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(to top, white, transparent);
}

.carousel-indicators {
    position: absolute;
    top: 50%;
    left: var(--margin-from-sides-of-page);
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.indicator {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: rgba(100, 100, 100, 0.35);
    cursor: pointer;
    /* box-shadow: 0 0px 12px rgba(0, 0, 0, 0.1); Subtle drop shadow */
}

.indicator.active {
    background-color: #404040;
    /* box-shadow: 0 0px 12px rgba(0, 0, 0, 0.35); Slightly stronger shadow for active indicator */
}