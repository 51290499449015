/* ErrorPage container */
.error-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
    padding: 20px;
    text-align: center;
}

/* Content area */
.error-content {
    max-width: 500px;
    background-color: #fff;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Illustration */
.error-illustration {
    width: 150px;
    margin-bottom: 20px;
}

/* Title */
.error-title {
    font-family: 'Arial', sans-serif;
    font-size: 28px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

/* Error message */
.error-message {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

/* Actions */
.error-actions {
    display: flex;
    justify-content: center;
    gap: 10px;
}

/* Button styling */
.error-button {
    background-color: #0077cc;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.error-button:hover {
    background-color: #005fa3;
}

/* Link styling */
.error-link {
    color: #0077cc;
    text-decoration: none;
    font-size: 14px;
}

.error-link:hover {
    text-decoration: underline;
}