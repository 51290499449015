.related-product {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 150px; /* Set a fixed width for uniform cards */
}

.product-image-wrapper {
    width: 150px; /* Fixed width */
    height: 150px; /* Fixed height */
    overflow: hidden; /* Masks any extra content outside the box */
    border-radius: 8px; /* Optional: Rounded corners */
    background-color: #f8f8f8; /* Placeholder background color */
}

.product-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image fills the area, masking the rest */
}

.product-name {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    margin: 0;
}

.manufacturer-name {
    font-size: 12px;
    font-weight: 400;
}