.app {
    position: relative;
    background-color: #ffffff; /* Optional: Set background to white */
}

.floating-logo {
    position: absolute;
    top: 20px; /* Distance from the top */
    left: calc(var(--margin-from-sides-of-page) - 10px); /* Distance from the left */
    width: 70px; /* Adjust the size of the logo */
    height: auto; /* Maintain aspect ratio */
    z-index: 10; /* Ensure it's above the HeroCarousel */
    background-color: #fff;
    padding: 10px;
}

h1 {
    font-size: 32px;
    font-family: 'SFPro Bold';
    margin-top: 10px;
    margin-bottom: 10px;
}

h3 {
    font-family: 'SFPro Medium';
    font-size: 17px;
    color: #000;
    margin-bottom: 15px;
}

.rating-stars {
    margin: 24px 0px 20px 0px;
}

.tabs-container {
    margin-top: 20px;
}

.rating-summary {
    margin: 15px 0px 40px 0px;
    padding-bottom: 30px;
    border-bottom: 1px solid #D1D1D1;
    border-radius: 0px;
}

.review-list {
    padding-bottom: 50px;
}