.product-specifications {
    background-color: #f8f8f8; /* Background color */
    padding: 15px; /* Padding around the component */
    border-radius: 10px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: #000; /* Text color */
}

.spec-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.spec-row:last-child {
    margin-bottom: 0; /* Remove margin for the last row */
}

.spec-key {
    font-weight: 500; /* Slightly bold for keys */
    flex: 0.5; /* Allow keys to take up flexible space */
}

.spec-value {
    text-align: right;
    font-weight: 400;
    flex: 1; /* Allow values to take up flexible space */
}