.product-video {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    position: relative;
}

.video-thumbnail-wrapper {
    position: relative;
    width: 150px;
    height: 250px;
}

.video-thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.play-button-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.play-icon {
    font-size: 20px;
    color: #000; /* Black play icon */
}

.play-button-overlay:hover {
    background-color: rgba(255, 255, 255, 1); /* Brighter on hover */
}