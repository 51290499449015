.rating-summary {
    background-color: #fff;
    width: 100%;
    max-width: 400px;
}

/* Overview Section */
.rating-overview {
    text-align: center;
    margin-bottom: 20px;
}

.rating-score {
    font-size: 48px;
    font-weight: bold;
    color: #000;
    margin: 0;
}

.rating-total {
    font-size: 14px;
    color: #555;
    margin-top: 5px;
}

/* Breakdown Section */
.rating-breakdown {
    margin-top: 20px;
}

.rating-row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.rating-label {
    flex: 1;
    font-size: 14px;
    color: #555;
}

.rating-bar {
    flex: 4;
    height: 6px;
    background-color: #eee;
    border-radius: 5px;
    overflow: hidden;
    margin: 0 10px;
}

.rating-bar-fill {
    height: 100%;
    background-color: #000;
    transition: width 0.3s ease;
}

.rating-count {
    flex: 1;
    text-align: right;
    font-size: 14px;
    color: #555;
}