.variant {
    padding: 10px 15px;
    border: 1px solid #d1d1d1;
    border-radius: 12px;
    background-color: #fff;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
    font-family: Arial, sans-serif;
    font-size: 14px;
}

.variant:hover {
    background-color: #f7f7f7;
}

.variant.selected {
    background-color: #e8f7ff;
    border-color: #0077cc;
}