.tabs-container {
    width: 100%;
    background-color: #fff; /* Ensure the background is solid */
}

.tabs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    position: sticky; /* Makes the tabs sticky */
    top: 0; /* Sticks to the very top of the screen */
    z-index: 100; /* Ensures it stays above other elements */
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
    margin-left: calc(var(--margin-from-sides-of-page) * -1);
    padding: 12px var(--margin-from-sides-of-page);
}

.tab {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #D1D1D1; /* Inactive tab color */
    font-weight: 400;
    padding: 0;
    font-family: 'SFPro Medium';
}

.tab.active {
    color: #000; /* Active tab color */
    font-family: 'SFPro Semibold';
}

.tab-content {
    padding: 8px 0;
    font-size: 16px;
    color: #000;
}
