.faq-container {
    font-size: 16px;
    color: #000;
    border: none;
}

.faq-item {
    margin: 0;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    cursor: pointer;
    user-select: none;
}

.faq-text {
    flex: 1;
    font-weight: 500;
}

.faq-indicator {
    font-size: 16px;
    color: #a1a1a1;
    transition: transform 0.3s ease;
    transform: rotate(0deg); /* Default (closed) state */
}

.faq-indicator.open {
    transform: rotate(180deg); /* Open state */
}

.faq-answer {
    padding: 8px 0 12px 0;
    font-size: 14px;
    color: #333;
    line-height: 1.6;
}

.faq-divider {
    height: 1px;
    background-color: #EDEDED; /* Light gray divider */
}
