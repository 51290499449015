.related-products-container {
    display: flex;
    gap: 16px;
    overflow: scroll;
    margin-left: calc(var(--margin-from-sides-of-page) * -1);
    padding-left: var(--margin-from-sides-of-page);
    width: calc(100% + var(--margin-from-sides-of-page));
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding-bottom: 5px;
}

.product-row {
    display: flex;
    justify-content: space-around; /* Spaces out products evenly */
    gap: 16px;
}

.related-product:last-child {
    margin-right: var(--margin-from-sides-of-page);
}