@font-face {
    font-family: 'SFPro';
    src: url('../assets/fonts/SFPro Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'SFPro Medium';
    src: url('../assets/fonts/SFPro Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'SFPro Semibold';
    src: url('../assets/fonts/SFPro Semibold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'SFPro Bold';
    src: url('../assets/fonts/SFPro Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

body {
    --margin-from-sides-of-page: 25px;
    font-family: 'SFPro', sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #fff;
}

#primary-container {
    padding: 0px var(--margin-from-sides-of-page) 0px var(--margin-from-sides-of-page);
}

a {
    text-decoration: underline;
    text-underline-offset: 4px;
    color: #000;
}