.product-review {
    background-color: #fff;
    margin-top: 30px;
}

.review-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.review-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.review-name {
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    color: #000;
    display: block;
    margin-bottom: 5px;
}

.review-date {
    font-size: 14px;
    color: #888;
    margin-left: 5px;
}

.review-body {
    margin-bottom: 0px;
}

.review-rating {
    font-size: 16px;
    margin-bottom: 5px;
    margin-right: 5px;
}

.filled-stars {
    color: #ffcc00; /* Orange color for filled stars */
}

.unfilled-stars {
    color: #d1d1d1; /* Gray color for unfilled stars */
}

.review-title {
    font-size: 16px;
    font-family: 'SFPro Semibold';
    margin-bottom: 5px;
}

.review-text {
    font-size: 14px;
    color: #555;
    line-height: 1.5;
    display: inline; /* Ensure the text and button are inline */
}

.read-more-button {
    background: none;
    border: none;
    color: #000;
    font-size: 14px;
    font-family: 'SFPro Medium';
    cursor: pointer;
    padding: 0;
    margin: 0; /* Remove top margin */
    margin-left: 5px;
    text-align: left;
    display: inline; /* Ensure button stays inline with text */
}

.read-more-button:hover {
    text-decoration: underline;
}

.review-footer {
    width: fit-content;
}

.upvote-button {
    background-color: rgba(0, 0, 0, 0);
    color: #000;
    border: 1px solid #D1D1D1;
    padding: 5px 12px 8px 12px;
    border-radius: 100px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.upvote-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
}