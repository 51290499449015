.product-description {
    font-size: 16px;
    line-height: 1.4;
    color: #000; /* Body text color */
}

.description-text {
    margin-bottom: 5px;
}

.ai-footnote {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #a1a1a1; /* Footnote text color */
    gap: 4px;
}

.ai-icon {
    font-size: 14px;
    color: #d1d1d1; /* Icon color */
}
